import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavigationMenuComponent } from './navigation-menu.component';
import { IonicModule } from '@ionic/angular';
import { RouterLink } from '@angular/router';

@NgModule({
	declarations: [NavigationMenuComponent],
	imports: [CommonModule, IonicModule, RouterLink],
	exports: [NavigationMenuComponent],
})
export class NavigationMenuModule {}
