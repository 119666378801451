<ion-app>
	<div *ngIf="rol == 'patient'">
		<ion-navigation-menu id="menuPatient"></ion-navigation-menu>
	</div>
	<div *ngIf="rol == 'therapist'">
		<ion-navigation-menu-therapist id="menuTherapist"></ion-navigation-menu-therapist>
	</div>
	<div *ngIf="!rol"></div>
	<ion-router-outlet></ion-router-outlet>
</ion-app>
