import { Component, OnInit, HostListener  } from '@angular/core';
import { register } from 'swiper/element/bundle';

import { NavigationEnd, Router } from '@angular/router';
import { Meta } from '@angular/platform-browser';
import { NotificationService } from './services/notification.service';

register();

@Component({
	selector: 'app-root',
	templateUrl: 'app.component.html',
	styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
	rol: any;
	isMobile: boolean;

	// Se deshabilita el click derecho en el proyecto
	@HostListener('contextmenu', ['$event'])
	onRightClick(event: Event) {
		event.preventDefault();
	}

	constructor(
		private router: Router,
		private meta: Meta,
		private notificationService: NotificationService,
	) {
		this.isMobile = this.detectMobile();
		this.router.events.subscribe((event) => {
			if (event instanceof NavigationEnd) {
				this.updateMetaDescription();
				const menuPatient = document.getElementById('menuPatient');
				const menuTherapist = document.getElementById('menuTherapist');
				if (menuPatient){
					if (event.url.includes('chat')) {
						menuPatient.style.zIndex = '-1000';
					}else{
						menuPatient.style.zIndex = '4';
					}
				}else{
					if(this.isMobile != true){
						if(event.url.includes('chat')){
							menuTherapist.style.zIndex = '-1000';
						}
						else{
							menuTherapist.style.zIndex = '4';
						}
					}else{
						if (event.url.includes('chat-patient')) {
							menuTherapist.style.zIndex = '-1000';
						}else if(event.url.includes('chat-support')){
							menuTherapist.style.zIndex = '-1000';
						}
						else{
							menuTherapist.style.zIndex = '4';
						}
					}

				}
			}
		});
	}

	ngOnInit() {
		let role = JSON.parse(localStorage.getItem('session'));

		this.rol = role == null || role == undefined ? false : role.user.role.name;
	}

	detectMobile(): boolean {
		const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
		const mobileWidth = 900; // Puedes ajustar este valor según tus necesidades

		return screenWidth < mobileWidth;
	}


	updateMetaDescription() {
		const currentRoute = this.router.routerState.snapshot.root;
		const metaDescription = currentRoute.firstChild?.data['metaDescription'];
		if (metaDescription) {
			this.meta.updateTag({ name: 'description', content: metaDescription });
		} else {
			this.meta.removeTag('name="description"');
		}
	}
}
