import {
	ActionPerformed,
	PushNotificationSchema,
	PushNotifications,
	DeliveredNotifications,
	Token,
} from '@capacitor/push-notifications';
import { App } from '@capacitor/app';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Clipboard } from '@capacitor/clipboard';
import { ApiService } from '@services/api.service';
import { AlertService } from '@services/alert.service';
import { Capacitor } from '@capacitor/core';
import { firebaseConfig } from '@env/firebaseconfig';
import { initializeApp } from 'firebase/app';
import { getMessaging, onMessage, getToken} from 'firebase/messaging';

@Injectable({
	providedIn: 'root',
})
export class NotificationService {
	isAppActive = true;

	constructor(
		private router: Router,
		private apiService: ApiService,
		private alertService: AlertService
	) {
		apiService.token.subscribe((token) => { // Token de auth
			if (token) {
				if (Capacitor.isPluginAvailable('PushNotifications'))
					this.register_mobile();
				else
					this.register_web();
			} else {
				if (Capacitor.isPluginAvailable('PushNotifications'))
					PushNotifications.removeAllListeners();
			}
		});

		App.addListener('appStateChange', (state) => {
			// Actualizar el estado de la aplicación
			this.isAppActive = state.isActive;
		});
	}

	register_web() {

		const firebaseConfig = {
			apiKey: "AIzaSyBYaAUGNGoxXWbqQ-6D_nxZLICMVcU1ZQI",
			authDomain: "mind2-cb107.firebaseapp.com",
			projectId: "mind2-cb107",
			storageBucket: "mind2-cb107.appspot.com",
			messagingSenderId: "400174742507",
			appId: "1:400174742507:web:c99fa2fc5ff67079cf37ae",
			measurementId: "G-65WGZ4CBTZ"
		};

		  // Initialize Firebase
		const app = initializeApp(firebaseConfig);
		const messaging = getMessaging(app);

		console.log('Dispositivo web registrado');
		getToken(messaging, {vapidKey: "BL2z83oKdwo220l9vFQ4eD90iYWXBPF6DuAPQMRvls-VThWmI-X4eeMyTaImN1JJsbJ-1vzhJHqh8cb-eWPWBek"})
			.then((token) => {
				console.log('Token: ' + token);
				this.apiService.updateFCMToken(token).subscribe({
					complete: () => {},
					error: (err) => console.log('Token de notificaciones no actualizado: ' + err.error.message),
				});
			})
			.catch((err) => {
				console.log('Permiso denegado: ' + err);
				this.alertService.toast('Por favor, verifica que tengas activadas las notificaciones.');
			});

		onMessage(messaging, (payload) => {
			console.log('Notificación recibida: ' + JSON.stringify(payload));
			const notification = payload.notification;
			const notificationTitle = notification.title;
			const notificationOptions = {
				body: notification.body,
				icon: notification.icon,
			};

			const notificationPromise = new Notification(notificationTitle, notificationOptions);
			notificationPromise.onclick = (event) => {
				console.log('Notificación abierta: ' + JSON.stringify(payload));
				event.preventDefault();
				window.open(payload.data['navigation_url'], '_blank');
			}
		});

	}

	register_mobile() {
		// if (!Capacitor.isPluginAvailable('PushNotifications')) {
		// 	console.log('PushNotifications plugin no disponible');
		// 	return;
		// }

		if (Capacitor.getPlatform() === 'android') {
			console.log('Creando canales de notificaciones (Android)');
			PushNotifications.createChannel({
				id: 'Chat',
				name: 'Chat',
				description: 'Conversaciónes entre terapeutas y pacientes',
				importance: 4,
				visibility: 1,
				lights: true,
				lightColor: '#3366',
				vibration: true,
			}).then(() =>
				PushNotifications.createChannel({
					id: 'Video-call',
					name: 'Video-call',
					description: 'Videollamadas entre terapeutas y pacientes',
					importance: 5,
					visibility: 1,
					lights: true,
					lightColor: '#3366',
					vibration: true,
				}),
			);
		}

		// On success, we should be able to receive notifications
		PushNotifications.addListener('registration', (token: Token) => {
			console.log('Registro exitoso. TOKEN: ' + JSON.stringify(token));
			this.apiService.updateFCMToken(token.value).subscribe({
				error: (err) => console.log('Token de notificaciones no actualizado: ' + err.error.message),
			});
			Clipboard.write({ string: token.value });
		});

		// Some issue with our setup and push will not work
		PushNotifications.addListener('registrationError', (error: any) =>
			console.log('Error en el registro de notificaciones: ' + JSON.stringify(error)),
		);

		// Show us the notification payload if the app is open on our device
		PushNotifications.addListener(
			'pushNotificationReceived',
			async (notificationReceived: PushNotificationSchema) => {
				if (this.isAppActive && this.router.url.includes('chat'))
					await PushNotifications.removeAllDeliveredNotifications();
			},
		);

		// Method called when tapping on a notification
		PushNotifications.addListener('pushNotificationActionPerformed', (notification: ActionPerformed) => {
			console.log('Notificacion abierta: ' + JSON.stringify(notification));
			const data = notification.notification.data;
			if (data.navigation_url) this.router.navigateByUrl(data.navigation_url);
		});

		this.notificationsPermissionRequest()
			.then(() => {
				console.log('PushNotifications plugin listo para recibir notificaciones');
			})
			.catch(() => {
				console.log('PushNotifications plugin no listo para recibir notificaciones');
			});
	}

	async notificationsPermissionRequest(): Promise<void> {
		return new Promise(async (resolve, reject) => {
			let result = await PushNotifications.requestPermissions();
			console.log('Resultado de permisos: ' + JSON.stringify(result));
			if (result.receive === 'prompt' || result.receive === 'prompt-with-rationale') {
				// Show a prompt to the user
				console.log('Soliciando permisos');
				await this.notificationsPermissionRequest();
			} else if (result.receive === 'granted') {
				// Register with Apple / Google to receive push via APNS/FCM
				console.log('Permiso concedido, registrando dispositivo');
				await PushNotifications.register();
				resolve();
			} else {
				// Show some error
				console.log('Permiso denegado, no recibirá notificaciones');
				await this.notificationsPermissionDenied();
				reject();
			}
		});
	}

	async notificationsPermissionDenied(): Promise<void> {
		return new Promise(async (resolve, reject) => {
			await this.alertService.presentAlertConfirm(
				'Advertencia',
				'No se han concedido los permisos para recibir notificaciones, ¿Desea concederlos?',
				'No',
				'Yes',
				() => {
					this.alertService.toast('No recibirá notificaciones');
					reject();
				},
				async () => {
					await this.notificationsPermissionRequest();
					resolve();
				},
			);
		});
	}
}
