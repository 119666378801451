import { NgModule } from '@angular/core';
import { RouteReuseStrategy } from '@angular/router';
import { CommonModule, NgIf } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { NavigationMenuModule } from './components/navigation-menu/navigation-menu.module';
import { NavigationMenuTherapistModule } from './components/navigation-menu-therapist/navigation-menu-therapist.module';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {ErrorManagementInterceptor} from "@services/interceptor/error-management.interceptor";

@NgModule({
	declarations: [AppComponent],
	imports: [
		BrowserModule,
		IonicModule.forRoot(),
		AppRoutingModule,
		HttpClientModule,
		NgIf,
		CommonModule,
		NavigationMenuModule,
		NavigationMenuTherapistModule,
		FormsModule,
		ReactiveFormsModule,
	],
	providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy }, { provide: HTTP_INTERCEPTORS, useClass: ErrorManagementInterceptor, multi: true }],
	bootstrap: [AppComponent],
})
export class AppModule {}
