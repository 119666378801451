import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AlertController } from '@ionic/angular';
import {StorageService} from "@services/storage.service";

@Injectable()
export class ErrorManagementInterceptor implements HttpInterceptor {

	constructor(private alertController: AlertController, private storageService: StorageService,) {
		this.listenToConnectionChanges();
	}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		if (!navigator.onLine) {
			this.presentNoConnectionAlert();
			return throwError(() => new Error('No internet connection'));
		}

		return next.handle(request).pipe(
			catchError((error: HttpErrorResponse) => {
				if (error.error.statusCode === 401) {
					this.storageService.deleteSession();
					window.location.href = '/';
				} else if (error.error.statusCode === 500) {
					this.presentServerErrorAlert();
				}
				return throwError(() => error);
			})
		);
	}

	async presentServerErrorAlert() {
		const alert = await this.alertController.create({
			header: 'Algo salió mal',
			message: 'Ocurrió un error con nuestro servidor, por favor inténtalo más tarde.',
			buttons: ['OK']
		});

		await alert.present();
	}

	async presentNoConnectionAlert() {
		const alert = await this.alertController.create({
			header: 'Sin conexión',
			message: 'No tienes conexión a internet. Por favor, verifica tu conexión e inténtalo de nuevo.',
			buttons: ['OK']
		});

		await alert.present();
	}

	listenToConnectionChanges() {
		window.addEventListener('offline', () => {
			this.presentNoConnectionAlert();
		});
		window.addEventListener('online', () => {
			// Opcional: Puedes realizar alguna acción cuando el usuario recupere la conexión
		});
	}
}
